import React from 'react';
import { Link, LinkProps } from 'react-scroll';

const NavigationLink = (props: LinkProps) => {
  const { to, children } = props;
  return (
    <Link
      activeClass="after:transition-all after:duration-300 after:ease-in-out after:bg-primary-dark after:dark:bg-white"
      className="relative font-medium text-sm md:text-base text-tertiary-dark dark:text-tertiary-light hover:text-tertiary-dark dark:hover:text-secondary-light focus:outline-blue-300 cursor-pointer after:transition-all after:duration-300 after:ease-in-out after:content-[''] after:absolute after:bottom-[-10px] after:left-0 after:w-[25px] after:h-0.5"
      to={to}
      href={to}
      spy={true}
      smooth={true}
      duration={500}
    >
      {children}
    </Link>
  );
};

export default NavigationLink;
