import React, { useState, useRef, useCallback } from 'react';

import ArrowLeft from '../../../images/arrrow-left.svg';
import ArrowRight from '../../../images/arrow-right.svg';
import { IProject } from 'lib/types/app';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import classNames from 'classnames';

interface ProjectGalleryModalProps {
  project: IProject;
}

const ProjectGalleryModal = ({ project }: ProjectGalleryModalProps) => {
  const swiperRef = useRef<SwiperRef | null>(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const onSlideChange = () => {
    if (!swiperRef.current) return;
    setIsEnd(swiperRef.current.swiper.isEnd);
    setIsBeginning(swiperRef.current.swiper.isBeginning);
  };

  return (
    <div className="flex flex-col justify-center h-full gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex gap-3">
          <button
            className="inline-flex items-center justify-center w-8 h-8 rounded-full disabled:opacity-30 text-tertiary-dark dark:text-tertiary-light bg-secondary-light hover:enabled:bg-tertiary-light dark:bg-primary-dark dark:enabled:hover:bg-secondary-dark focus:outline-blue-300"
            disabled={isBeginning}
            onClick={handlePrev}
          >
            <ArrowLeft className="w-4 h-4" />
          </button>
          <button
            className="inline-flex items-center justify-center w-8 h-8 rounded-full disabled:opacity-30 text-tertiary-dark dark:text-tertiary-light bg-secondary-light hover:enabled:bg-tertiary-light dark:bg-primary-dark dark:enabled:hover:bg-secondary-dark focus:outline-blue-300"
            disabled={isEnd}
            onClick={handleNext}
          >
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
        <div className="flex gap-3">
          <button
            className={classNames(
              'w-fit  text-xs font-medium me-2 px-2.5 py-0.5 rounded',
              !isAndroid ? 'bg-blue-100 text-blue-800' : 'bg-gray-100',
            )}
            onClick={() => setIsAndroid(false)}
          >
            iOS
          </button>
          <button
            className={classNames(
              'w-fit text-xs font-medium me-2 px-2.5 py-0.5 rounded',
              isAndroid ? 'bg-blue-100 text-blue-800' : 'bg-gray-100',
            )}
            onClick={() => setIsAndroid(true)}
          >
            Android
          </button>
        </div>
      </div>
      <div className="relative">
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          slidesPerView={3}
          centeredSlides={false}
          slidesPerGroupSkip={1}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
          onSlideChange={onSlideChange}
          onSwiper={onSlideChange}
        >
          {project.mobileScreenshots?.map((img, idx) => (
            <SwiperSlide key={idx}>
              <img
                key={idx}
                src={
                  isAndroid
                    ? `screenshots/${project.mobileScreenshotsSlug}/${img}-android.svg`
                    : `screenshots/${project.mobileScreenshotsSlug}/${img}.svg`
                }
                alt={isAndroid ? 'Android Screenshot' : 'iOS Screenshot'}
                className="md:h-[485px]"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProjectGalleryModal;
