export const getTheme = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return window.__theme;
};

export const setPreferredTheme = (theme: string) => {
  window.__setPreferredTheme(theme);
};
