import React, { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'gatsby';
import classNames from 'classnames';

import ThemeToggle from 'components/ThemeToggle';
import NavigationLink from './NavigationLink';

const Navigation = () => {
  const [sticky, setSticky] = useState(false);

  const handleStickyNavbar = () => {
    if (window.scrollY >= 168) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleStickyNavbar);
  }, []);

  const scrollToTop = () => scroll.scrollToTop();

  return (
    <>
      <nav className="bg-primary-light dark:bg-primary-dark">
        <div className="max-w-screen-xl mx-auto px-6 py-8 md:px-20 md:py-10">
          <div className="mb-4">
            <Link
              className="text-2xl text-tertiary-dark dark:text-tertiary-light focus:outline-blue-300"
              onClick={scrollToTop}
              to="/"
            >
              <span>👨‍💻</span>
              <span className="font-semibold pl-4">Mohamed Samara</span>
            </Link>
          </div>

          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center gap-6">
              <NavigationLink to="home">Home</NavigationLink>
              <NavigationLink to="about">About</NavigationLink>
              <NavigationLink to="projects">Projects</NavigationLink>
              <NavigationLink to="contact">Reach me!</NavigationLink>
            </div>
            <ThemeToggle />
          </div>
        </div>
      </nav>
      <div
        className={classNames(
          'fixed z-[100] w-full transition-all duration-300 ease-in-out bg-primary-light dark:bg-primary-dark border-b border-b-light-primary dark:border-b-secondary-dark',
          sticky ? 'visible top-0' : 'invisible top-[-100%]',
        )}
      >
        <div className="max-w-screen-xl mx-auto px-6 py-8 md:px-20 md:py-4">
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center gap-6">
              <Link
                className="relative font-medium text-tertiary-dark dark:text-tertiary-light hover:text-tertiary-dark dark:hover:text-secondary-light focus:outline-blue-300 cursor-pointer"
                onClick={scrollToTop}
                to="/"
              >
                Home
              </Link>
              <NavigationLink to="about">About</NavigationLink>
              <NavigationLink to="projects">Projects</NavigationLink>
              <NavigationLink to="contact">Reach me!</NavigationLink>
            </div>
            <ThemeToggle />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
