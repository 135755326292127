import * as React from 'react';
import { graphql } from 'gatsby';
import { Element } from 'react-scroll';

import { IProjectData, IGithubData } from 'lib/types/app';
import { getProjects } from 'lib/utils/projects';
import Layout from 'components/Layout';
import { About, Projects, Contact } from 'components/Home';
import SEO from 'components/SEO';
import { Fade } from 'components/Animations';
import SectionContainer from 'components/SectionContainer';

interface IndexPageProps {
  data: {
    allProjectsJson: IProjectData;
    githubData: IGithubData;
  };
}

const IndexPage = (props: IndexPageProps) => {
  const projects = getProjects(props.data);

  return (
    <Layout>
      <SEO />
      <section>
        <Element name="about">
          <Fade>
            <SectionContainer>
              <About />
            </SectionContainer>
          </Fade>
        </Element>
        <Element name="projects">
          <Fade>
            <SectionContainer>
              <Projects projects={projects} />
            </SectionContainer>
          </Fade>
        </Element>
        <Element name="contact">
          <Fade>
            <SectionContainer>
              <Contact />
            </SectionContainer>
          </Fade>
        </Element>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const projectsQuery = graphql`
  query {
    allProjectsJson {
      edges {
        node {
          title
          name
          description
          tech
          url
          source
          thumbnailSlug
          type
          mobileScreenshotsSlug
          mobileScreenshots
        }
      }
    }
    githubData {
      data {
        user {
          repositories {
            edges {
              node {
                name
                description
                url
                licenseInfo {
                  name
                }
                forkCount
                stargazers {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`;
