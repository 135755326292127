import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useTheme } from 'lib/context/Theme';
import { useIsMounted } from 'lib/hooks';
import Moon from '../../images/moon.svg';
import Sun from '../../images/sun.svg';

const ThemeToggle = () => {
  const { dark, toggle } = useTheme();
  const isMounted = useIsMounted();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (isMounted()) setReady(true);
  }, [isMounted]);

  return (
    <label
      className={classNames(
        'relative w-[40px] h-[40px] flex items-center justify-between duration-200 rounded-full bg-primary-light hover:bg-secondary-light dark:bg-primary-dark dark:hover:bg-secondary-dark',
        dark ? 'hover:animate-spin-slow' : 'hover:animate-zoom-in',
      )}
    >
      <input
        type="checkbox"
        className="absolute w-full h-full -translate-x-1/2 rounded-full appearance-none cursor-pointer left-1/2 peer focus:outline-blue-300"
        checked={dark}
        onChange={(e) => {
          toggle(e.target.checked);
        }}
      />

      <span className="flex items-center justify-center p-2">
        {ready && (
          <>
            {dark ? (
              <Sun className="transition-all duration-300 ease-in-out" />
            ) : (
              <Moon className="transition-all duration-300 ease-in-out" />
            )}
          </>
        )}
      </span>
    </label>
  );
};

export default ThemeToggle;
