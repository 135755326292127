export const MY_LINKEDIN = 'https://www.linkedin.com/in/mohamed-samara';
export const MY_GITHUB = 'https://github.com/mohamedsamara';
export const GATSBY_LINK = 'https://www.gatsbyjs.com';
export const NETLIFY_LINK = 'https://www.netlify.com';

export enum PROJECT_THUMBNAIL_SLUGS {
  MERN_ECOMMERCE = 'MERN_ECOMMERCE',
  MOVEINZ = 'MOVEINZ',
  GYM_BUDDY_WEB = 'GYM_BUDDY_WEB',
  SERVICE_PRO = 'SERVICE_PRO',
  WEB5_CHAT_APP = 'WEB5_CHAT_APP',
  CHATTY_APP = 'CHATTY_APP',
  NEXT_CRYPTO_SAAS = 'NEXT_CRYPTO_SAAS',
  NEXT_SAAS = 'NEXT_SAAS',
  NEXT_NFT_GALLERY = 'NEXT_NFT_GALLERY',
  VUE_CRYPTO_SAAS = 'VUE_CRYPTO_SAAS',
  GO_VUE_AUTH = 'GO_VUE_AUTH',
  REACT_SNACKBAR_MESSAGES = 'REACT_SNACKBAR_MESSAGES',
}

export enum PROJECT_TYPE {
  MVP = 'MVP',
  SAAS = 'SAAS',
  LIBSANDAPI = 'LIBSANDAPI',
}

export const PROJECT_TYPE_LABELS = {
  MVP: 'MVP',
  SAAS: 'SaaS',
  LIBSANDAPI: 'Libraries and APIs',
};
