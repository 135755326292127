import { IProjectData, IGithubRepo, IGithubData } from 'lib/types/app';

export const getGithubRepos = async () => {
  const projectsMap = new Map<string, IGithubRepo>();
  const pages = [1, 2];

  const projects: IGithubRepo[] = [];
  for (const page of pages) {
    const response = await fetch(
      `https://api.github.com/users/mohamedsamara/repos?page=${page}&&per_page=100`,
    );
    const data = (await response.json()) as IGithubRepo[];
    projects.push(...data);
  }

  for (const project of projects) {
    projectsMap.set(project.name, project);
  }

  return projectsMap;
};

export const getProjects = (data: {
  allProjectsJson: IProjectData;
  githubData: IGithubData;
}) => {
  const {
    allProjectsJson: { edges: projects },
  } = data;

  return projects.map((project) => {
    const { node } = project;
    return {
      ...node,
    };
  });
};
