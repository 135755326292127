import React from 'react';
import { createPortal } from 'react-dom';

type PortalProps = React.HTMLAttributes<HTMLElement> & {
  id?: string;
};

const portalRoot =
  typeof document !== `undefined` ? document.getElementById('portal') : null;

const Portal = (props: PortalProps) => {
  const { id = '', className = '', children } = props;
  const el =
    typeof document !== `undefined` ? document.createElement('div') : null;

  const wrapper = React.useRef<HTMLElement>(el);

  React.useEffect(() => {
    const current = wrapper.current as HTMLElement;
    if (!current || !portalRoot) return;

    current.setAttribute('id', id);
    current.setAttribute('class', className);

    portalRoot.appendChild(current);

    return () => {
      portalRoot.removeChild(current);
    };
  }, [wrapper, id, className]);

  if (!wrapper.current) {
    return <>{null}</>;
  }

  return createPortal(children, wrapper.current);
};

export default Portal;
