import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  ReactNode,
} from 'react';

import { getTheme, setPreferredTheme } from './mode';

interface IThemeContext {
  dark: boolean;
  toggle: (checked: boolean) => void;
}

interface IThemeProvider {
  children: ReactNode;
}

const initialState = {
  dark: false,
  toggle: () => {},
};

const ThemeContext = createContext<IThemeContext>(initialState);

const ThemeProvider = (props: IThemeProvider) => {
  const { children } = props;
  const [dark, setDark] = useState(false);

  useEffect(() => {
    const theme = getTheme() === 'dark' ? true : false;
    setDark(theme);
  }, []);

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        const theme = event.matches ? 'dark' : 'light';
        setDark(theme === 'dark');
      });
  }, []);

  const toggle = (checked: boolean) => {
    setDark(checked);
    setPreferredTheme(checked ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{ dark, toggle }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);
export { ThemeProvider, useTheme };
