import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface SectionContainerProps {
  children: ReactNode;
  className?: string;
}

const SectionContainer = (props: SectionContainerProps) => {
  const { className, children } = props;

  return (
    <section className={classNames('relative overflow-hidden', className)}>
      <div className="min-h-screen max-w-screen-xl mx-auto px-6 md:px-20 py-28 md:py-24 flex flex-col justify-center">
        {children}
      </div>
    </section>
  );
};

export default SectionContainer;
