import React from 'react';
import { Link } from 'react-scroll';

import { MY_GITHUB, MY_LINKEDIN } from 'lib/constants';
import Card from 'components/Card';

const About = () => {
  return (
    <div className="w-full">
      <h3 className="mb-5 text-3xl font-bold text-tertiary-dark dark:text-tertiary-light">
        About me
      </h3>
      <Card>
        {/* Header */}
        <div className="flex flex-col items-center gap-8 py-6 md:flex-row md:items-start">
          <div className="overflow-hidden border border-gray-200 rounded-full basis-24 bg-primary-light dark:border-secondary-dark">
            <img src="profile.jpg" alt="Profile" className="w-[100px]" />
          </div>
          <div className="flex flex-col justify-center flex-1">
            <div className="mb-4">
              <h3 className="mb-4 text-xl font-bold capitalize md:text-2xl text-tertiary-dark dark:text-tertiary-light">
                <span className="mb-4 text-3xl font-medium">
                  Hey, I’m <span className="text-blue-600">Mohamed</span> 👋
                </span>
              </h3>
              <p className="mb-4 text-tertiary-dark dark:text-tertiary-light">
                I’m a software engineer and certified AWS developer with more
                than 7 years of experience in the field. I have experience in
                both frontend and backend development, with a focus on React,
                React Native, Node, and occasionally Vue and Go.
              </p>
              <p className="text-tertiary-dark dark:text-tertiary-light">
                All of my open source projects can be found on{' '}
                <a
                  href={MY_GITHUB}
                  target="_blank"
                  title="github"
                  rel="noreferrer noopener"
                  className="text-blue-500 underline focus:outline-blue-300"
                >
                  Github
                </a>
                .{' '}
                <span className="pl-1">A few I&apos;m proud of are listed</span>
                <Link
                  className="pl-1 text-blue-500 underline cursor-pointer focus:outline-blue-300"
                  to="projects"
                  href="projects"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Here
                </Link>
              </p>
            </div>
            <div className="flex flex-wrap gap-2 text-center">
              <ul>
                <li className="inline-block">
                  <a
                    href={MY_LINKEDIN}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="flex items-center justify-center w-12 h-12 mr-4 text-blue-500 duration-200 border border-gray-200 rounded-full dark:border-secondary-dark dark:text-white bg-secondary-light hover:bg-tertiary-light dark:bg-primary-dark dark:hover:bg-secondary-dark focus:outline-blue-300"
                  >
                    <i className="text-2xl fab fa-linkedin" />
                  </a>
                </li>
                <li className="inline-block">
                  <a
                    href={MY_GITHUB}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="flex items-center justify-center w-12 h-12 mr-4 text-black duration-200 border border-gray-200 rounded-full dark:border-secondary-dark dark:text-white bg-secondary-light hover:bg-tertiary-light dark:bg-primary-dark dark:hover:bg-secondary-dark focus:outline-blue-300"
                  >
                    <i className="text-2xl fab fa-github" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default About;
