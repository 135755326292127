import React, { ReactNode } from 'react';

const Card = ({ children }: { children: ReactNode }) => {
  return (
    <div className="rounded-lg shadow py-6 px-6 md:px-8 border border-gray-200 dark:border-secondary-dark bg-secondary-light dark:bg-tertiary-dark">
      {children}
    </div>
  );
};

export default Card;
