import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

interface FadeProps {
  children: ReactNode;
}

const Fade = (props: FadeProps) => {
  const { children } = props;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{
        ease: 'easeInOut',
        duration: 0.5,
        delay: 0.3,
      }}
    >
      {children}
    </motion.div>
  );
};

export default Fade;
