import { useEffect, useState } from 'react';

import { IGithubRepo } from 'lib/types/app';
import { getGithubRepos } from 'lib/utils';

export type TGithubRepos = Map<string, IGithubRepo>;

export const useRepos = () => {
  const [repos, setRepos] = useState<TGithubRepos>(new Map());
  useEffect(() => {
    const fetchRepos = async () => {
      const repos = await getGithubRepos();
      setRepos(repos);
    };

    fetchRepos();
  }, []);

  return { repos };
};
