import React from 'react';

import { IProject } from 'lib/types/app';
import ProjectItem from './ProjectItem';
import { useRepos } from 'lib/hooks';

interface ProjectsProps {
  projects: IProject[];
}
const Projects = (props: ProjectsProps) => {
  const { projects } = props;
  const { repos } = useRepos();

  const mvp = projects.filter((p) => p.type === 'MVP');
  const saas = projects.filter((p) => p.type === 'SAAS');
  const libs = projects.filter((p) => p.type === 'LIBSANDAPI');

  return (
    <div className="space-y-10">
      {/* MVP */}
      <div className="w-full">
        <h3 className="mb-2 text-3xl font-bold text-tertiary-dark dark:text-tertiary-light">
          MVP Projects
        </h3>
        <p className="mb-5 text-lg text-tertiary-dark dark:text-tertiary-light">
          List of Minimum Viable Product
        </p>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
          {mvp.map((project, idx) => (
            <ProjectItem key={idx} project={project} repos={repos} />
          ))}
        </div>
      </div>

      {/* SaaS */}
      <div className="w-full">
        <h3 className="mb-5 text-3xl font-bold text-tertiary-dark dark:text-tertiary-light">
          SaaS Templates
        </h3>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
          {saas.map((project, idx) => (
            <ProjectItem key={idx} project={project} repos={repos} />
          ))}
        </div>
      </div>

      {/* Libs */}
      <div className="w-full">
        <h3 className="mb-5 text-3xl font-bold text-tertiary-dark dark:text-tertiary-light">
          Libraries & APIs
        </h3>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
          {libs.map((project, idx) => (
            <ProjectItem key={idx} project={project} repos={repos} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
