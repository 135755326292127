import React from 'react';

import { NETLIFY_LINK, GATSBY_LINK } from 'lib/constants';
import GatsbyIcon from '../../images/gatsby-logo.svg';
import NetlifyIcon from '../../images/netlify-logo.svg';

const Footer = () => {
  return (
    <footer className="border-t bg-secondary-light dark:bg-secondary-dark border-t-gray-200 dark:border-t-secondary-dark">
      <div className="container flex flex-col justify-between px-6 py-10 mx-auto md:py-6 md:items-center md:flex-row md:px-24 xl:px-64">
        <div className="mb-4 md:mb-0">
          <h4 className="text-lg font-bold text-tertiary-dark dark:text-tertiary-light">
            🚀 Made by <span className="text-blue-600">Mohamed</span>
          </h4>
        </div>
        <div>
          <p className="text-gray-600 dark:text-gray-400">
            Built on
            <a
              href={GATSBY_LINK}
              target="_blank"
              rel="noreferrer noopener"
              className="mx-1 duration-200 text-tertiary-dark dark:text-tertiary-light focus:outline-blue-300"
            >
              <GatsbyIcon className="inline-block w-4 h-4 mr-1" />
              <span>Gatsby</span>
            </a>
            and deployed on
            <a
              href={NETLIFY_LINK}
              target="_blank"
              rel="noreferrer noopener"
              className="mx-1 duration-200 text-tertiary-dark dark:text-tertiary-light focus:outline-blue-300"
            >
              <NetlifyIcon className="inline-block w-4 h-4 mr-1" />
              <span>Netlify</span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
