import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

import { ThemeProvider } from 'lib/context/Theme';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';

interface LayoutProps {
  children: ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <ThemeProvider>
      <div className="site-container">
        <Navigation />
        <main className="bg-primary-light dark:bg-primary-dark">
          <motion.div
            initial={{
              opacity: 0,
              x: -200,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            exit={{
              opacity: 0,
              x: 200,
            }}
            transition={{
              type: 'spring',
              mass: 0.35,
              stiffness: 75,
              duration: 0.5,
              delay: 0.3,
            }}
          >
            {children}
          </motion.div>
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
